import {Component} from '@angular/core';

@Component({
    selector: 'eaglei-api-key-management',
    templateUrl: './api-key-management.component.html',
    styleUrls: ['./api-key-management.component.scss'],
    standalone: false,
})
export class ApiKeyManagementComponent {
    // Nav Bar Properties
    public selectedTab: string = 'request';
    public numberOfRequests: number = 0;

    constructor() {}

    /**
     * Changes the active tab.
     * @param status The tab that will be set to active
     */
    public changeTab(status: string): void {
        this.selectedTab = status;
    }
}

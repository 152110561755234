import {Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatTableDataSource} from '@angular/material/table';
import {DenyOptionsComponent} from '../../../../../shared/modals/deny-options/deny-options.component';
import {ApiKeyRequest} from '../../classes/api-key-request';
import {filter} from 'rxjs/operators';
import {ApiKeyService} from '../../services/api-key.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {ApplicationConfig} from '../../../../classes/application-config';
import {LoadingMaskOptions} from '../../../../classes/loading-mask-options';
import {ModalConfig} from '../../../../classes/modal-config';
import {ApiKeyData} from '../../classes/api-key-data';

@Component({
    selector: 'eaglei-api-key-request-list',
    templateUrl: './api-key-request-list.component.html',
    styleUrls: ['./api-key-request-list.component.scss'],
    standalone: false,
})
export class ApiKeyRequestListComponent {
    // HTML Properties
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @Output() numberOfRequests: EventEmitter<number> = new EventEmitter<number>();

    // Table Properties
    public readonly columnNames = [
        'username',
        'numberRequested',
        'dateRequested',
        'userSupported',
        'systemSupported',
        'dataService',
        'ogcService',
        'reason',
        'action',
    ];
    public readonly mainColumns = ['username', 'numberRequested'];
    public readonly expandColumns = [
        'blank',
        'blank',
        'dateRequested',
        'userSupported',
        'systemSupported',
        'dataService',
        'ogcService',
        'reason',
        'action',
    ];
    public dataSource: MatTableDataSource<ApiKeyData>;
    public expandedDataSource: MatTableDataSource<ApiKeyRequest>;

    // Properties
    public expanded: ApiKeyData;
    public readonly denialReasons: string[] = [
        'Required Profile Fields Not Complete',
        'Access Restrictions: Access to EAGLE-I APIs is limited to federal government staff and their support contractors that are Emergency ' +
            'Support Function (ESF) mission partners of the Department of Energy.',
    ];

    // Mask Methods
    public pendingApproval: boolean;
    public approvalButtonText: string = 'Approve';

    constructor(private apiKeyService: ApiKeyService, private modal: MatDialog, private popup: MatSnackBar) {
        this.expandedDataSource = new MatTableDataSource<ApiKeyRequest>();
        this.showMask();
        this.apiKeyService.getApiKeyRequest().subscribe((res) => {
            this.numberOfRequests.emit(res.length);
            this.initializeData(ApiKeyData.fromList(res));
        });
    }

    // DataSource Methods
    /**
     * Initialized the table data source and sets all paging, filtering, and sorting, if a dataSource already exists only the data is updated.
     * @param requests The api-key requests that will populate the table.
     */
    private initializeData(requests: ApiKeyData[]): void {
        if (this.dataSource) {
            this.dataSource.data = requests;
        } else {
            this.dataSource = new MatTableDataSource(requests);
            this.dataSource.filterPredicate = this.filterPredicate.bind(this);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
            this.dataSource.sort = this.sort;
        }
        this.hideMask();
    }

    /**
     * Filters the table to users whose name, email, username, or organization match the text passed in
     * @param request Each api-key request to be compared
     * @param text the text that is being searched for
     */
    private filterPredicate(request: ApiKeyData, text: string): boolean {
        const fields = [request.username, request.email];
        return fields.some((field) => {
            return field.toLowerCase().includes(text.toLowerCase());
        });
    }

    /**
     * Sorts the table by the given columnName
     * @param request Each api-key request to be compared
     * @param columnName the column name that is being sorted on
     */
    public sortingDataAccessor(request: ApiKeyData, columnName: string): string | number {
        switch (columnName) {
            case 'username':
                return request.username.toLowerCase();
            case 'requested':
                return request.keys.length;
        }
    }

    // Filter methods
    /**
     * Searches the Table and update dates the datasource filter value.
     * @param searchText The text to be searched
     */
    public searchRequests(searchText: string): void {
        searchText = searchText === '' ? ' ' : searchText;
        this.dataSource.filter = searchText;
    }

    // Action Methods
    /**
     * Approved the request for an API-KEY
     * @param request The request to be approved.
     * @param event The mouse event to catch and stop
     */
    public approveRequest(request: ApiKeyRequest, event: Event): void {
        event.stopPropagation();

        this.pendingApproval = true;
        this.approvalButtonText = 'Loading';

        const success = (res) => {
            this.pendingApproval = false;
            this.approvalButtonText = 'Approve';
            this.popup.open('Request Approved', 'Okay', {duration: 5000, panelClass: 'dialog-success'});
            this.removeRequest(res);
        };

        const failure = (error) => {
            console.error(error);
            this.pendingApproval = false;
            this.approvalButtonText = 'Approve';
            const errorText = error.userMessage || 'Error Approving Request';
            this.popup.open(errorText, 'Okay ', {panelClass: 'dialog-failure'});
        };

        this.apiKeyService.approveApiKeyRequest(request).subscribe(success, failure);
    }

    /**
     * Denies a pending request
     * @param request The request to be denied
     * @param reason The reason for denial
     */
    public denyRequest(request: ApiKeyRequest, reason: string): void {
        const success = (res) => {
            this.popup.open('Request Deny', 'Okay', {duration: 5000, panelClass: 'dialog-success'});
            this.removeRequest(res);
        };

        const failure = (error) => {
            console.error(error);
            const errorText = error.userMessage || 'Error Denying Request';
            this.popup.open(errorText, 'Okay', {panelClass: 'dialog-failure'});
        };

        this.apiKeyService.denyApiKeyRequest(request, reason).subscribe(success, failure);
    }

    // Utility Method
    /**
     * Removed the request from the table and updates the number of pending requests on the badge in the page header. If there are no more
     * requests for the user, the user will also be removed from the table.
     * @param request The request to be removed
     */
    private removeRequest(request: ApiKeyRequest): void {
        const parentIndex = this.dataSource.data.findIndex((d) => d.userId === request.userId);
        const requests = this.dataSource.data[parentIndex].keys;
        const keyIndex = requests.findIndex((key) => key.id === request.id);

        if (keyIndex !== -1) {
            requests.splice(keyIndex, 1);
        }

        if (requests.length === 0) {
            this.dataSource.data.splice(parentIndex, 1);
            this.dataSource._updateChangeSubscription();
        } else {
            this.expandedDataSource.data = requests;
            this.expandedDataSource._updateChangeSubscription();
        }

        // Updating the value in the request number badge
        const requestSum = this.dataSource.data.reduce((prev, cur) => (prev += cur.keys.length), 0);
        this.numberOfRequests.emit(requestSum);
    }

    /**
     * Opens the deny option modal to create a custom denial reason.
     * @param request The request to be denied
     */
    public createDenyOption(request: ApiKeyRequest): void {
        const config: MatDialogConfig = {
            width: ModalConfig.getModalWidth(),
        };
        this.modal
            .open(DenyOptionsComponent, config)
            .afterClosed()
            .pipe(filter((reason) => !!reason))
            .subscribe((reason) => this.denyRequest(request, reason));
    }

    /**
     * Toggles the expanded row
     * @param row the row to be expanded.
     */
    public toggleExpansion(row: ApiKeyData): void {
        this.expanded = this.expanded === row ? (undefined as any) : row;

        if (this.expanded) {
            this.expandedDataSource.data = this.expanded.keys;
            this.expandedDataSource._updateChangeSubscription();
        }
    }

    /**
     * Configures and displays the loading mask
     */
    public showMask(): void {
        const config = new LoadingMaskOptions();
        config.showMask = true;
        ApplicationConfig.pageMask.next(config);
    }

    /**
     * Hides the loading mask.
     */
    public hideMask(): void {
        const config = ApplicationConfig.pageMask.getValue();
        config.showMask = false;
        ApplicationConfig.pageMask.next(config);
    }

    public hasEllipsis(element: HTMLTableDataCellElement): boolean {
        return (window as any).elementHasEllipsis(element);
    }
}
